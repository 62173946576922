@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&display=swap");

body,
html,
#root {
  height: 100%;
  padding: 0;
  margin: 0;
	background-color: #eee;
}

* {
  font-family: "Montserrat", sans-serif;
}
